// import { Router } from "next/router";
import React from "react";
import { useRouter } from "next/router";
import { useWindowSize } from "api/commonConstants";
import { useState } from "react";
import { useSession } from "next-auth/react";
import dynamic from "next/dynamic";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
// import { useEffect } from "react";
// import app from "utils/firebase";
import "react-toastify/dist/ReactToastify.css";
// import {
//   fetchAndActivate,
//   getRemoteConfig,
//   getString,
// } from 'firebase/remote-config';
const CustomPopup = dynamic(() =>
  import("../../components/shared/modal/customPopup")
);
export const QuizCard = ({ data, type }) => {
  const router = useRouter();
  const [openClaimModel, setOpenClaimModel] = useState(false);
  const { data: session } = useSession();
  const { userLocalDetail } = useSelector(({ AuthReducer }) => AuthReducer);
  const size = useWindowSize();
  const [loginShow, setLoginShow] = useState(false);
  function encodeString(text) {
    var encoded = "";
    for (var i = 0; i < text.length; i++) {
      var charCode = text.charCodeAt(i);
      // Shift the character code by a fixed value
      var shiftedCharCode = charCode + 1;
      encoded += String.fromCharCode(shiftedCharCode);
    }
    var encoded2 = encoded.replace(/\:/g, "TYc9PO8");
    return encoded2;
  }
  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const remoteConfig = getRemoteConfig(app);
  //     remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

  //     fetchAndActivate(remoteConfig)
  //       .then(() => {
  //         const titleData = getString(remoteConfig, 'app_version');
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // });

  const playQuiz = () => {
    if (userLocalDetail?.id || session?.user?.email) {
      let encodedID = encodeString(data?.quizId);
      console.log("Encoded ID is ", encodedID);
      if (data?.state === "active") {
        router.replace(`/quiz/live/${encodedID}`);
      } else {
        router.replace(`/quiz/previous/${encodedID}`);
      }
      //setShowDropdown((e) => !e);
    } else {
      if (setLoginShow) setLoginShow(true);
    }
  };
  const viewQuiz = () => {
    let encodedID = data?.userQuizId;
    if (userLocalDetail?.id || session?.user?.email) {
      router.push(`/quiz/view/${encodedID}`);
      //setShowDropdown((e) => !e);
    } else {
      if (setLoginShow) setLoginShow(true);
    }
  };

  const notifyMe = () => {
    return toast.success("Thanks for Subscribing! You will be notified");
  };
  const date = new Date(data?.startDate).toDateString().split(" ");
  let time = new Date(data?.startDate)
    .toLocaleString(undefined, { timeZone: "Asia/Kolkata" })
    .split(" ");
  console.log(data?.state);
  return (
    <>
      {size.width < 650 && (
        <div className="quizCard">
          <div className="quizCardUpper">
            <div className="quizImageCard">
              <img
                onClick={() => {
                  data?.state === "previous"
                    ? viewQuiz()
                    : data?.state === "upcoming"
                    ? notifyMe()
                    : playQuiz();
                }}
                src={data.headingImageUrl}
              ></img>
            </div>
            <div className="quizCardUpperText">
              <div className="childFlex">
                <img src="/assets/images/quiz/rupee.png" width="12px"></img>{" "}
                Prize: ₹ {data?.prizePerUser}*
              </div>
              <div className="childFlex">
                <img src="/assets/images/quiz/question.png" width="12px"></img>{" "}
                {data.questionCount} Questions
              </div>
              <div className="childFlex">
                <img src="/assets/images/quiz/time.png" width="12px"></img>{" "}
                {data.duration.slice(3)} Mins{" "}
              </div>
            </div>
          </div>
          <div className="quizCardLower">
            <p className="quizHeading">{data.title}</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              {data?.state === "previous" ? (
                <button onClick={() => viewQuiz()} className="quizKheleDone">
                  View Quiz <span style={{ marginLeft: "5px" }}> &gt;</span>
                </button>
              ) : data?.state === "upcoming" ? (
                <button onClick={() => notifyMe()} className="quizKhele">
                  Notifiy Me
                  <img
                    src="/assets/images/quiz/notify.png"
                    width="15px"
                    style={{ marginLeft: "10px" }}
                  ></img>
                </button>
              ) : (
                <button onClick={() => playQuiz()} className="quizKhele">
                  Play Quiz <span style={{ marginLeft: "5px" }}> &gt;</span>
                </button>
              )}
              {type === "live" && (
                <div className="quizCardTimeBox">
                  <h6 className="quizCardTime">
                    <span style={{ color: "#DE217D" }}>Date:</span>{" "}
                    {date[2] + " " + date[1]}
                  </h6>
                  <h6 className="quizCardTime">
                    <span style={{ color: "#DE217D" }}>Time:</span>{" "}
                    {time[1].slice(0, time[1].length - 3) + " PM"}
                  </h6>
                </div>
              )}
              {type === "previous" && (
                <div className="quizCardTimeBox">
                  <h6 className="quizCardTime">
                    <span style={{ color: "#DE217D" }}>Date:</span>{" "}
                    {date[2] + " " + date[1]}
                  </h6>
                  <h6 className="quizCardTime">
                    <span style={{ color: "#DE217D" }}>Time:</span>{" "}
                    {time[1].slice(0, time[1].length - 3) + " PM"}
                  </h6>
                </div>
              )}
            </div>
            {<hr></hr>}
            {
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {data?.state === "previous" ? (
                  <>
                    <div
                      onClick={() =>
                        router.push(`/quiz/leaderboard/${data?.quizId}`)
                      }
                      className="previousQuizLeaderboard"
                    >
                      Leaderboard{" "}
                      <span style={{ marginLeft: "5px" }}> &gt;</span>
                      <span>&gt;</span>
                    </div>
                  </>
                ) : (
                  <h6 className="top50">
                    *Top {data?.prizeSlots} players will win ₹
                    {data?.prizePerUser} each
                  </h6>
                )}
                {
                  <img
                    onClick={() => setOpenClaimModel(true)}
                    src="/assets/images/quiz/danger.png"
                    width="15px"
                    height="15px"
                  ></img>
                }
              </div>
            }
          </div>
        </div>
      )}
      {size.width > 650 && (
        <div className="quizCard-desktop">
          <div className="desktop-quizCard-img-div">
            <img
              className="desktop-quizCard-image"
              onClick={() => {
                data?.state === "previous"
                  ? viewQuiz()
                  : data?.state === "upcoming"
                  ? notifyMe()
                  : playQuiz();
              }}
              src={data?.headingImageUrl}
              width="300px"
              height="auto"
              style={{ cursor: "pointer" }}
            ></img>
          </div>
          <div className="rightQuizCard-desktop">
            <div className="quizCardUpperText-desktop">
              <div className="childFlex-desktop">
                <img
                  src="/assets/images/quiz/rupee_pink.png"
                  width="14px"
                ></img>{" "}
                Prize: ₹{data?.prizePerUser}*
              </div>
              <div className="childFlex-desktop">
                <img
                  src="/assets/images/quiz/question_pink.png"
                  width="14px"
                ></img>{" "}
                {data?.questionCount} Questions
              </div>
              <div className="childFlex-desktop">
                <img src="/assets/images/quiz/time_pink.png" width="14px"></img>{" "}
                {data?.duration.slice(3)} Mins{" "}
              </div>
            </div>
            <div className="quizCardLower-desktop">
              <p className="quizHeading-desktop">{data?.title}</p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {data?.state === "previous" ? (
                  <button onClick={() => viewQuiz()} className="quizKheleDone">
                    View Quiz <span style={{ marginLeft: "5px" }}> &gt;</span>
                  </button>
                ) : data?.state === "upcoming" ? (
                  <button onClick={() => notifyMe()} className="quizKhele">
                    Notifiy Me
                    <img
                      src="/assets/images/quiz/notify.png"
                      width="15px"
                      style={{ marginLeft: "10px" }}
                    ></img>
                  </button>
                ) : (
                  <button onClick={() => playQuiz()} className="quizKhele">
                    Play Quiz <span style={{ marginLeft: "5px" }}> &gt;</span>
                  </button>
                )}
                {type === "live" && (
                  <div>
                    <h6 className="quizCardTime-desktop">
                      <span style={{ color: "#DE217D" }}>Date:</span>{" "}
                      {date[2] + " " + date[1]}
                    </h6>
                    <h6 className="quizCardTime-desktop">
                      <span style={{ color: "#DE217D" }}>Time:</span>{" "}
                      {time[1].slice(0, time[1].length - 3) + " PM"}
                    </h6>
                  </div>
                )}
                {type === "previous" && (
                  <div>
                    <h6 className="quizCardTime-desktop">
                      <span style={{ color: "#DE217D" }}>Date:</span>{" "}
                      {date[2] + " " + date[1]}
                    </h6>
                    <h6 className="quizCardTime-desktop">
                      <span style={{ color: "#DE217D" }}>Time:</span>{" "}
                      {time[1].slice(0, time[1].length - 3) + " PM"}
                    </h6>
                  </div>
                )}
              </div>
              {<hr></hr>}
              {
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {data?.state === "previous" ? (
                    <>
                      <div
                        onClick={() =>
                          router.push(`/quiz/leaderboard/${data?.quizId}`)
                        }
                        className="previousQuizLeaderboard"
                      >
                        Leaderboard{" "}
                        <span style={{ marginLeft: "5px" }}> &gt;</span>
                        <span>&gt;</span>
                      </div>
                    </>
                  ) : (
                    <h6 className="top50">
                      *Top {data?.prizeSlots} players will win ₹
                      {data?.prizePerUser} each
                    </h6>
                  )}
                  {(data?.state === "upcoming" || data?.state === "active") && (
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpenClaimModel(true)}
                      src="/assets/images/quiz/danger.png"
                      width="15px"
                      height="15px"
                    ></img>
                  )}
                </div>
              }
            </div>
          </div>
        </div>
      )}
      <CustomPopup showModal={loginShow} onClose={() => setLoginShow(false)} />
      {/* <ToastContainer></ToastContainer> */}
      <Modal
        show={openClaimModel}
        fullscreen={false}
        centered
        size="md"
        onHide={() => setOpenClaimModel(false)}
      >
        <div className="modal-claim-header">
          <div className="">
            <div role={"button"} onClick={() => setOpenClaimModel(false)}>
              <span className="icon-close"></span>
            </div>
          </div>
        </div>
        <hr></hr>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              margin: "10px",
            }}
          >
            <div
              style={{ color: "#EB0E8C", fontSize: "18px", fontWeight: "600" }}
            >
              आपका स्कोर इन बातों पर निर्भर करेगा
            </div>
            <div>
              <ol>
                <li style={{ fontSize: "14px", fontWeight: "500" }}>
                  आपने प्रश्न का उत्तर सही दिया है या नहीं.
                </li>
                <li style={{ fontSize: "14px", fontWeight: "500" }}>
                  आप कितनी जल्दी प्रश्नों का उत्तर देते हैं.
                </li>
              </ol>
            </div>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
                textDecoration: "underline",
                lineHeight: "1.5",
              }}
            >
              पॉइंट्स की गणना
            </p>
            <p>पहला स्टेज -</p>
            <p>
              हर सही जवाब पर आपको मिलेंगे 50 पॉइंट और गलत जवाब के लिए मिलेंगे 0
              पॉइंट.
            </p>
            <p>दूसरा स्टेज</p>
            <p>
              मान लिया कि हर सवाल का जवाब देने के लिए दिया गया कुल समय X
              मिलीसेकंड है.<br></br>
              प्रश्न का उत्तर देने में लगा समय Y मिलीसेकंड है.<br></br>
              प्रश्न का उत्तर देने के बाद बचा समय (X-Y) मिलीसेकंड होगा.<br></br>
              समय आधारित हिस्से से लिए आपका स्कोर होगा-<br></br>((X-Y)/X)x50
              <br></br>
            </p>
            <p>
              आपका फाइनल स्कोर दोनों स्टेज के पॉइंट्स को जोड़कर तय किया जाएगा
            </p>
            <p>
              इस तरह से आप किसी भी प्रश्न का सही जवाब देकर सर्वाधिक 100 पॉइंट पा
              सकते हैं और सबसे कम 0 पॉइंट मिलेंगे.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
